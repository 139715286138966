@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;
  }
  .heading1_bold {
    font-size: clamp(2.25rem, 5vw, 4.5rem);
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1;
    line-height: 52px;
  }

  @media (min-width: 640px) {
    .heading1_bold {
      font-size: 4.5rem;
      letter-spacing: -2.5px;
      line-height: 1;
      line-height: 72px;
    }
  }

  .heading2_semibold {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    line-height: 52px;
  }

  @media (min-width: 640px) {
    .heading2_semibold {
      font-size: 64px;
      font-weight: 600;
      letter-spacing: -1.2px;
      line-height: 72px;
    }
  }

  .heading4_semibold {
    font-size: 28px;
    font-weight: 600;
    line-height: 2.25rem;
  }

  @media (min-width: 640px) {
    .heading4_semibold {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .heading5_semibold {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  @media (min-width: 640px) {
    .heading5_semibold {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 36px;
    }
  }

  .heading6_semibold {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }

  @media (min-width: 640px) {
    .heading6_semibold {
      font-size: 1.25rem;
      letter-spacing: -0.2px;
      line-height: 1.75rem;
    }
  }

  .subheading {
    font-family: soehne-buch, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  @media (min-width: 640px) {
    .subheading {
      font-size: 1.125rem;
      letter-spacing: 0.2px;
      line-height: 1.75rem;
    }
  }

  .subheading2 {
    font-size: 1.125rem;
  }

  .subheading2,
  .subheading3 {
    font-family: soehne-buch, sans-serif;
    font-weight: 400;
    line-height: 1.75rem;
  }

  .subheading3 {
    font-size: 1rem;
  }

  @media (min-width: 640px) {
    .subheading3 {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  .paragraph {
    font-family: soehne-buch, sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }

  @media (min-width: 640px) {
    .paragraph {
      font-size: 1rem;
      letter-spacing: 0.2px;
      line-height: 1.5rem;
    }
  }

  .paragraph2 {
    font-family: soehne-buch, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 1.5rem;
  }

  .paragraph3 {
    letter-spacing: 0.2px;
  }

  .paragraph3,
  .paragraph4 {
    font-family: soehne-buch, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  @media (min-width: 640px) {
    .paragraph4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .paragraph5 {
    font-size: 1rem;
    letter-spacing: 0.2px;
    line-height: 1.5rem;
  }

  .paragraph5,
  .support_text {
    font-family: soehne-buch, sans-serif;
    font-weight: 400;
  }

  .support_text,
  .support_text_medium {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .support_text_medium {
    font-family: soehne-kraftig, sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .heading5_semibold_mob {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 36px;
  }
  .content-list {
    display: contents;
  }

  .content-list + .content-list:before {
    color: #0000001f;
    content: "|";
  }
  @media (max-width: 640px) {
    .content-list + .content-list:before {
      display: none;
    }
  }
  .btn {
    @apply main_btn_color rounded-full px-4 py-2 text-gray-200 bg-linear-gradient-blue justify-center border border-black/[12%] shadow-[0px_2px_2px_0px_#FFFFFF38_inset] inline-flex text-base font-semibold tracking-[0.2px] w-fit hover:opacity-80 duration-200;
  }
  .footer-dropdown-menu {
    @apply hidden;
  }
  button:focus + .footer-dropdown-menu {
    @apply block;
  }
}
.main_btn_color {
  background-image: linear-gradient(180deg, #0a5078, #2a375e);
}


@keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Adjust scale to make the beat effect larger or smaller */
  }
}

.animate-beat {
  animation: beat 1s infinite ease-in-out; /* The logo will "beat" every second */
}